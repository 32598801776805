body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 15px;
}

body {
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #f6f5f7;
  background-color: #131722 !important;
  height: 100vh;
  /* 131722 */
  /* background-image: url('https://i.pinimg.com/originals/8a/84/f6/8a84f6081395d2337e77552ae2998041.jpg'); */
}

#root {
  height: inherit;
}

.root {
  height: inherit;
}

.form-control {
  background-color: #131722 !important;
  color: #d8d8d8 !important;
}

.btn-outline-light {
  border-color: #b2b5be !important;
}

.navbar {
  background-color: none !important;

  font-weight: bold;
}

.navbar-brand {
  font-size: x-large !important
}

.navbar-nav {
  font-size: 12pt;

}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

table,
input,
.badge,
.btn-group-vertical button,
.btn-group-sm button {

  font-size: 10pt !important;
}

td {
  background-color: #131722 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.nav-tabs .nav-item button {
  padding: 4px 10px;
  font-size: 10pt;
  color: rgb(214, 214, 214);
}

.tab-content div .container {
  padding: 0px !important;
}