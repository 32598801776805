.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}


#the-background {
    height: 1000px;
    background-image: url('https://images4.alphacoders.com/110/thumb-1920-1108171.png');
    background-position: center;
    background-size: cover;
}
/* .nav-link {
    font-size: medium;
    font-weight: bold;
    color: lightgray !important
} */